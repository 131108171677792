import { SideBarFull } from "../components/SideBarFull"
import { useQuery } from "@tanstack/react-query"
import { getAllUnreadThreads } from "@/features/conversations/api"
import { TThread } from "@/features/conversations/types"
import { TMenuItems, TSideBarItem } from "../types/SideBarItem"
import { Sheet, SheetClose, SheetContent } from "@/core/components/Sheet"
import { SideBarCompact } from "../components/SideBarCompact"
import { appsAtom } from "@/atoms/apps"
import { useRecoilValue, useRecoilValueLoadable } from "recoil"
import { COMPANIES_APP } from "@/utils/api/apps"
import { meAtom } from "@/atoms/users"
import { isSubspace } from "@/atoms/spaces"
import { useTranslation } from "next-i18next"
import { Illustration } from "@/core/components/Illustration"
import { Icon } from "@/core/components/icon/Icon"
import { Button } from "@/core/components/Button"
import { useEffect, useState } from "react"
import { useRouter } from "next/router"

const convertMenuItemsToSections = (
  menuItems: TMenuItems,
  filterLocked = false,
): { sections: { items: TSideBarItem[] }[] } => {
  return {
    sections: Object.entries(menuItems).map(([index, section]) => {
      return {
        items: Object.entries(section)
          .map(([index, item]) => {
            if (filterLocked && item.locked) return null
            if (item.subItem) {
              return {
                ...item,
                subItem: Object.entries(item.subItem).map(
                  ([index, subItem]) => {
                    return {
                      ...subItem,
                      subItem: null,
                    }
                  },
                ),
              }
            }
            return item as TSideBarItem
          })
          .filter((e) => e !== null) as TSideBarItem[],
      }
    }),
  }
}

export const SideBar = () => {
  const { t } = useTranslation("common")
  const router = useRouter()
  const [open, setOpen] = useState(false)
  const { data: unreads, isLoading: isLoadignUnread } = useQuery<TThread[]>({
    queryKey: ["threads/all-unreads"],
    queryFn: () => getAllUnreadThreads({ limit: 100 }),
  })
  const { contents: apps, state } = useRecoilValueLoadable(appsAtom)
  const user: any = useRecoilValue(meAtom)
  const isSupplier = useRecoilValue(isSubspace)

  useEffect(() => {
    router.events.on("routeChangeStart", () => setOpen(false))
    return () => {
      router.events.off("routeChangeStart", () => setOpen(false))
    }
  }, [router.events])

  if (state === "loading") return null

  const hasTimelineApp = apps?.find((app) => app.name === "Timeline")
  const hasPeople = apps?.some((app) => app.tags.includes("People"))
  const hasTimeRegistration = apps?.find((app) => app.name === "Timesheets")
  const hasCompanies = apps.some((app) => app.uid === COMPANIES_APP)
  const hasOpportunities = !isSupplier // or AI
  const isCustomer = !isSupplier
  const hasContracts = true
  const hasAnalytics = apps?.some((app) => app.tags.includes("Analytics"))

  const menuItems: TMenuItems = {
    home: {
      home: {
        label: "home",
        icon: "house",
        href: "/",
        imageSrc: null,
        subItem: null,
        locked: false,
      },
      messages: {
        label: "messages",
        icon: "chat_bubble",
        href: "/inbox",
        value: !isLoadignUnread ? unreads?.length : 0,
        imageSrc: null,
        subItem: null,
        locked: false,
      },
    },
    tasks: {
      opportunities: {
        label: "opportunities",
        icon: "radar",
        href: "/projects/AI",
        imageSrc: null,
        subItem: null,
        locked: !hasOpportunities,
      },
      requests: {
        label: "requests",
        icon: "inbox_text",
        href: null,
        imageSrc: null,
        locked: false,
        subItem: {
          internal_requests: {
            label: "own_requests",
            icon: "inbox_text",
            href: "/shared",
            imageSrc: null,
            subItem: null,
            locked: !isCustomer,
          },
          external_requests: {
            label: "shared_requests",
            icon: "inbox_text",
            href: "/supplier/opportunities",
            imageSrc: null,
            subItem: null,
            locked: false,
          },
        },
      },
      ongoing_tasks: {
        label: "ongoing_tasks",
        icon: "work",
        href: "/active",
        imageSrc: null,
        subItem: null,
        locked: false,
      },
    },
    resources: {
      companies: {
        label: "customers_and_partners",
        icon: "corporate_fare",
        href: "/companies",
        imageSrc: null,
        subItem: null,
        locked: !hasCompanies,
      },
      customer_agreements: {
        label: "customer_agreements",
        icon: "contract",
        href: "/agreements",
        imageSrc: null,
        subItem: null,
        locked: !isCustomer,
      },
      resources: {
        label: "resources",
        icon: "group",
        href: "/people",
        imageSrc: null,
        subItem: null,
        locked: !hasPeople,
      },
      contracts: {
        label: "contracts",
        icon: "receipt",
        href: "/contracts",
        imageSrc: null,
        subItem: null,
        locked: !hasContracts,
      },
    },
    analytics: {
      analytics: {
        label: "analytics",
        icon: "monitoring",
        href: "/analytics",
        imageSrc: null,
        subItem: null,
        locked: !hasAnalytics,
      },
      timeline: {
        label: "timeline",
        icon: "view_timeline",
        href: "/timeline",
        imageSrc: null,
        subItem: null,
        locked: !hasTimelineApp,
      },
      time_sheet: {
        label: "time_sheet",
        icon: "overview",
        href: "/time_registration",
        imageSrc: null,
        subItem: null,
        locked: !hasTimeRegistration,
      },
    },
    settings: {
      profile_settings: {
        label: "profile",
        icon: "person",
        href: "/settings/profile",
        imageSrc: user.email,
        subItem: null,
        locked: !true,
      },
    },
  }

  const menuItemsSections = convertMenuItemsToSections(menuItems, isCustomer)

  return (
    <div className="z-50 h-screen border-r">
      <div className="h-full 2xl:hidden">
        <Sheet open={open} onOpenChange={(isOpen) => setOpen(isOpen)}>
          <SideBarCompact t={t} sections={menuItemsSections.sections} />
          <SheetContent
            aria-describedby=""
            className="bg-container-base flex h-full w-full !max-w-none flex-col p-3 sm:w-[19rem] sm:rounded-tr-xl"
            side="left"
          >
            <SheetClose className="h-16 p-3 pl-2.5">
              <Button
                variant="text"
                size="icon"
                className="p-2"
                text={t("close")}
              >
                <Icon icon="menu_open" className="pl-1 pt-1 text-2xl" />
              </Button>
            </SheetClose>
            <SideBarFull sections={menuItemsSections.sections} />
          </SheetContent>
        </Sheet>
      </div>
      <div className="bg-container-base hidden h-full w-[19rem] flex-col p-3 2xl:flex">
        <div className="flex flex-col px-3 py-6">
          <Illustration
            name="flextribe-x"
            alt="Logo symbol"
            height={48}
            width={48}
          />
        </div>
        <div className="h-full">
          <SideBarFull sections={menuItemsSections.sections} />
        </div>
      </div>
    </div>
  )
}
