"use client"

import { Toaster as Sonner } from "sonner"

type ToasterProps = React.ComponentProps<typeof Sonner>

const Snackbar = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      className="toaster group sm:min-w-[616px] sm:max-w-[827px]"
      position="bottom-center"
      closeButton
      icons={{}}
      offset={{ right: "300px" }}
      toastOptions={{
        unstyled: true,
        className:
          "bg-inverse-surface text-inverse-on-surface grid grid-cols-16 gap-2 rounded-md min-h-12 px-3 py-1 w-full",
        classNames: {
          toast: "mx-auto py-1 flex items-center px-3 py-1",
          content: "text-sm font-bold col-span-10",
          icon: "col-span-1 col-start-1 mx-0.5",
          actionButton:
            "cursor-pointer col-start-13 w-fit col-span-4 hover:bg-inverse-surface text-sm py-1 px-3",
          closeButton:
            "cursor-pointer w-4 h-4 scale-150 col-start-16 row-span-1 translate-y-[100%] rounded-full !bg-inverse-surface border-none text-inverse-on-surface",
        },
      }}
      {...props}
    />
  )
}

export { Snackbar }
