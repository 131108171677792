import {
  faFaceSmile,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faHandFingersCrossed,
  faLightbulbOn,
  faMinusCircle,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"

export const DEFAULT_CURRENCY = "EUR"
export const DEFAULT_LOCALE = "en-US"

export const CURRENCIES = ["EUR", "USD", "DKK", "NOK", "SEK"]

export const LOCALES = {
  "da-DK": "Denmark",
  "en-US": "USA",
  "no-NO": "Norway",
  "sv-SE": "Sweden",
}

export const ADDRESS_FIELDS = [
  {
    label: "Street",
    placeholder: "Ragnagade 7",
    key: "street",
  },
  {
    label: "Zip code",
    placeholder: "2100",
    key: "zipcode",
  },
  {
    label: "City",
    placeholder: "København Ø",
    key: "city",
  },
  {
    label: "State",
    placeholder: "Zealand",
    key: "state",
  },
  {
    label: "Country",
    placeholder: "Country",
    key: "country",
  },
]
const defaultConfig = {
  bgColor: "bg-surface",
  color: "text-blue-500",
}
const pdfConfig = {
  bgColor: "bg-red-100",
  color: "text-red-400",
  icon: faFilePdf,
}
const wordConfig = {
  bgColor: "bg-blue-100",
  color: "text-blue-500",
  icon: faFileWord,
}
const exelConfig = {
  bgColor: "bg-green-100",
  color: "text-green-500",
  icon: faFileExcel,
}
const ppConfig = {
  bgColor: "bg-orange-100",
  color: "text-orange-500",
  icon: faFilePowerpoint,
}

export const iconClasses: {
  [key: string]: {
    bgColor: string
    color: string
    icon: IconDefinition
  }
} = {
  // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
  // Media
  image: { ...defaultConfig, icon: faFileImage },
  audio: { ...defaultConfig, icon: faFileAudio },
  video: { ...defaultConfig, icon: faFileVideo },
  // Documents
  "application/pdf": pdfConfig,
  "application/msword": wordConfig,
  "application/vnd.ms-word": wordConfig,
  "application/vnd.oasis.opendocument.text": wordConfig,
  "application/vnd.openxmlformats-officedocument.wordprocessingml": wordConfig,
  "application/vnd.ms-excel": exelConfig,
  "application/vnd.openxmlformats-officedocument.spreadsheetml": exelConfig,
  "application/vnd.oasis.opendocument.spreadsheet": exelConfig,
  "application/vnd.ms-powerpoint": ppConfig,
  "application/vnd.openxmlformats-officedocument.presentationml": ppConfig,
  "application/vnd.oasis.opendocument.presentation": ppConfig,
  "text/plain": { ...defaultConfig, icon: faFileLines },
  "text/html": { ...defaultConfig, icon: faFileCode },
  "application/json": { ...defaultConfig, icon: faFileCode },
  // Archives
  "application/gzip": { ...defaultConfig, icon: faFileArchive },
  "application/zip": { ...defaultConfig, icon: faFileArchive },
}

export const mimeTypesMapper = {
  // Images
  "image/jpeg": "JPEG",
  "image/png": "PNG",
  // Audio
  "audio/mpeg": "MP3 Audio",
  // Video
  "video/mp4": "MP4 Video",
  // Documents
  "application/pdf": "PDF Document",
  "application/msword": "Word Document",
  "application/vnd.ms-word": "Word Document",
  "application/vnd.oasis.opendocument.text": "OpenDocument Text",
  "application/vnd.openxmlformats-officedocument.wordprocessingml":
    "Word Document (XML)",
  "application/vnd.ms-excel": "Excel Document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml":
    "Excel Document (XML)",
  "application/vnd.oasis.opendocument.spreadsheet": "OpenDocument Spreadsheet",
  "application/vnd.ms-powerpoint": "PowerPoint",
  "application/vnd.openxmlformats-officedocument.presentationml":
    "PowerPoint (XML)",
  "application/vnd.oasis.opendocument.presentation":
    "OpenDocument Presentation",
  // Plain Text
  "text/plain": "Plain Text",
  // HTML
  "text/html": "HTML",
  // JSON
  "application/json": "JSON",
  // Archives
  "application/gzip": "GZIP Archive",
  "application/zip": "ZIP Archive",
  "image/webp": "WEBP",
  // Add more MIME types and their corresponding text here
}

export const HEX_OPACITY = {
  5: "0D",
  10: "1A",
  15: "26",
  20: "33",
  25: "40",
  30: "4D",
  35: "59",
  40: "66",
  45: "73",
  50: "80",
  55: "8C",
  60: "99",
  65: "A6",
  70: "B3",
  75: "BF",
  80: "CC",
  85: "D9",
  90: "E6",
  95: "F2",
  100: "FF",
}

export const CUSTOMER_STATUSES = [
  {
    value: "opportunity",
    label: "Opportunity",
    icon: faHandFingersCrossed,
    color: "#3A96EB",
  },
  {
    value: "lead",
    label: "Lead",
    icon: faLightbulbOn,
    color: "#E89255",
  },
  {
    value: "customer",
    label: "Customer",
    icon: faFaceSmile,
    color: "#00BF8A",
  },
  {
    value: "lost",
    label: "Lost",
    icon: faMinusCircle,
    color: "#CE3F40",
  },
] as const

// TODO: Remove once new notifications are stable
export const NotificationTypes = {
  TASK_CHANGES: "TASK_CHANGES",
  NEW_MESSAGE: "NEW_MESSAGE",
  NEW_TALENT: "NEW_TALENT",
} as const
