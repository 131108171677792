import { Divider } from "core/components/Divider"
import { SideBarFullItem } from "./SideBarFullItem"
import { SideBarLogo } from "./SideBarLogo"
import { cn } from "@/utils/helpers"
import type { TSideBarItem } from "../types/SideBarItem"

type TSidebarFullProps = {
  sections: {
    items: TSideBarItem[]
  }[]
}

export const SideBarFull = ({ sections }: TSidebarFullProps) => {
  return (
    <div className="flex h-full w-full flex-col overflow-y-scroll [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden">
      {sections.map((section, index) => (
        <div
          key={index}
          className={cn({ "mt-auto": index === sections.length - 1 })}
        >
          {index !== 0 && index !== sections.length - 1 && (
            <div className="px-3">
              <Divider className="opacity-30" />
            </div>
          )}
          {section.items.map((item, index) => (
            <SideBarFullItem
              key={index}
              label={item.label}
              icon={item.icon}
              href={item.href}
              value={item.value}
              imageSrc={item.imageSrc}
              subItem={item.subItem}
              locked={item.locked}
            />
          ))}
        </div>
      ))}
    </div>
  )
}
