import { Icon } from "@/core/components/icon/Icon"
import { TIconName } from "@/core/components/icon/IconUtil"
import { ContractSigneeListView } from "@/modules/contracts/models/contractSigneeListView"
import { cn } from "@/utils/helpers"
import { HTMLAttributes } from "react"

type SigneeStatusProps = HTMLAttributes<HTMLDivElement> & {
  signee: ContractSigneeListView
}

export const SigneeStatus = ({
  signee,
  className,
  ...props
}: SigneeStatusProps) => {
  const isSigned = signee.signature.isSigned
  const isPending = !isSigned && signee.shouldSign
  const isNotRequired = !signee.shouldSign
  let icon: TIconName = "schedule"

  if (isSigned || isNotRequired) {
    icon = "check"
  }

  return (
    <div
      {...props}
      className={cn(
        "flex h-4 w-4 items-center justify-center rounded-full",
        className,
        isSigned && "bg-green-400 text-white",
        isPending && "bg-orange-300 text-white",
        isNotRequired && "bg-slate-300 text-white",
      )}
    >
      <Icon icon={icon} className="text-xs" />
    </div>
  )
}
