import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { TaskKanbanStatus } from "../../core/constants/taskKanbanStatus"
import { CandidateListView } from "@/modules/candidate/models/candidateListView"

@Serializable
export class OpportunityListView {
  uid: string = ""
  name: string = ""
  departmentId: string = ""
  departmentName: string = ""
  caseNumber: string = ""
  startDate: string = ""
  endDate: string = ""
  startEndDate: any = ""
  internalDeadline: string = ""
  kanbanStatus: number = TaskKanbanStatus.NotDefined
  ownerId: string = ""
  ownerName: string = ""
  customerId: string = ""
  customerName: string = ""
  leadId: string = ""
  leadName: string = ""
  leadEmail: string = ""
  mainTaskName: string = ""
  candidates: CandidateListView[] = []

  constructor(
    data: {
      uid: string
      name: string
      departmentId: string
      departmentName: string
      caseNumber: string
      startDate: string
      endDate: string
      startEndDate: any
      internalDeadline: string
      kanbanStatus: number
      ownerId: string
      ownerName: string
      customerId: string
      customerName: string
      leadId: string
      leadName: string
      leadEmail: string
      mainTaskName: string
      candidates: CandidateListView[]
    } | null = null,
  ) {
    if (data) {
      this.uid = data.uid
      this.name = data.name
      this.departmentId = data.departmentId
      this.departmentName = data.departmentName
      this.caseNumber = data.caseNumber
      this.startDate = data.startDate
      this.endDate = data.endDate
      this.startEndDate = data.startEndDate
      this.internalDeadline = data.internalDeadline
      this.kanbanStatus = data.kanbanStatus
      this.ownerId = data.ownerId
      this.ownerName = data.ownerName
      this.customerId = data.customerId
      this.customerName = data.customerName
      this.leadId = data.leadId
      this.leadName = data.leadName
      this.leadEmail = data.leadEmail
      this.mainTaskName = data.mainTaskName
      this.candidates = data.candidates
    }
  }
}
