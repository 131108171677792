import { SheetTrigger } from "core/components/Sheet"
import { SideBarCompactItem } from "./SideBarCompactItem"
import { Icon } from "@/core/components/icon/Icon"
import type { TSideBarItem } from "../types/SideBarItem"
import { Divider } from "@/core/components/Divider"
import { SideBarLogo } from "./SideBarLogo"
import { ImageWithFallback } from "@/utils/ImageWithFallback"
import { Illustration } from "@/core/components/Illustration"
import { Button } from "@/core/components/Button"
import { TFunction } from "next-i18next"

type TSidebarFullProps = {
  sections: {
    items: TSideBarItem[]
  }[]
  t: TFunction
}

export const SideBarCompact = (props: TSidebarFullProps) => (
  <div className="absolute flex h-20 items-center gap-4 px-4 py-4">
    <SheetTrigger>
      <Button
        variant="text"
        size="icon"
        className="p-2"
        text={props.t("common:menu")}
      >
        <Icon icon={"menu"} className="text-2xl" />
      </Button>
    </SheetTrigger>
    <Illustration
      name="flextribe-logo"
      alt="Logo symbol"
      height={24}
      width={145}
    />

    {/* <div className="flex h-full flex-col gap-2 pb-2">
      {props.sections.map((section, index) => (
        <div key={index} className="group flex flex-col gap-2.5 last:mt-auto">
          <Divider className="mx-auto my-0 mt-2 w-3/4 opacity-30 group-first:hidden group-[&:nth-last-child(-n+2)]:hidden" />
          {section.items.map((item, index) => (
            <SideBarCompactItem
              key={index}
              label={item.label}
              icon={item.icon}
              href={item.href}
              imageSrc={item.imageSrc}
              subItem={item.subItem}
              locked={item.locked}
            />
          ))}
        </div>
      ))}
    </div> */}
  </div>
)
