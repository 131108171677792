// DO NOT CHANGE THIS, must be done in the backend and then copied here.
//
// We need to ensure they are ordered, so Won always has the highest value.
export enum TaskKanbanStatus {
  NotDefined = 0,
  NoCandidates = 1,
  InProgress = 10, // Nominated and other columns below a QA process.
  UnderConsideration = 70, // Another option is to move all requests to this section if one or more candidates are nominated AND the internal due date is passed. The status should in that case be “Under consideration” (Til vurdering), which today is equivalent to the QA stage in the Kanban board.
  Interview = 73,
  Offered = 75, // “Proposal sent” (Tilbud sendt) should be triggered if/when one or more nominated candidates are moved to the equivalent column in the Kanban board.
  Withdrawn = 80,
  Cancelled = 88,
  Lost = 90,
  Won = 100,
}
