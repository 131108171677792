import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/utils/helpers"

const labelVariants = cva(
  "flex h-5 w-fit px-1 min-w-5 items-center justify-center rounded-full text-center text-xs leading-none",
  {
    variants: {
      variant: {
        default: "bg-primary text-on-primary",
        important: "bg-red-600 text-on-primary",
        neutral: "bg-primary/20",
        interactive: "bg-secondary/20",
        plain: "bg-transparent text-on-surface-variant",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof labelVariants> {
  count?: number
}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, variant, count, ...props }, ref) => {
    const [prevCount, setPrevCount] = React.useState(count)
    const [animate, setAnimate] = React.useState(false)

    React.useEffect(() => {
      if (count !== prevCount) {
        setAnimate(true)
        setTimeout(() => setAnimate(false), 300) // Duration of the animation
        setPrevCount(count)
      }
    }, [count, prevCount])

    const formatCount = (count: number | undefined) => {
      if (count === undefined) return ""
      return count > 99 ? "99+" : count.toString()
    }

    return (
      <div
        className={cn(
          labelVariants({ variant, className }),
          {
            "animate-countChange": animate,
          },
          className,
        )}
        ref={ref}
        {...props}
      >
        {formatCount(count)}
      </div>
    )
  },
)
Badge.displayName = "Badge"

export { Badge }
