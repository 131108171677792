import qs from "qs"
import { fetcher } from "../../../utils/api"
import {
  TInboxView,
  TMessage,
  TMessageMultiple,
  TThread,
  TThreadQueryParams,
} from "../types"

export const getAllUnreadThreads = async (
  params: TThreadQueryParams,
): Promise<TThread[]> => {
  const queryString = qs.stringify({
    view: "unread",
    skip: 0,
    limit: 9999,
    ...(params ?? {}),
  })
  return await fetcher(`/conversations?${queryString}`)
}

export const getThreads = async (
  params: TThreadQueryParams,
): Promise<TThread[]> => {
  const queryString = qs.stringify({ skip: 0, limit: 9999, ...(params ?? {}) })
  return await fetcher(
    `/conversations${!!queryString ? `?${queryString}` : ""}`,
  )
}

export const getThread = async (thread: string): Promise<TThread> => {
  return await fetcher(`/conversations/${thread}`)
}

export const getThreadMessages = async (
  thread: string,
  params?: { skip: number; limit: number },
): Promise<TMessage[]> => {
  const queryString = qs.stringify({ skip: 0, limit: 9999, ...(params ?? {}) })
  return await fetcher(`/conversations/${thread}/messages?${queryString}`)
}

export const getThreadCount = async (params: {
  view: TInboxView
  task?: string | undefined | null
}): Promise<number> => {
  const queryString = qs.stringify({ view: "unread", ...(params ?? {}) })
  const res = await fetcher(`/conversations/count?${queryString}`)
  return res?.count ?? 0
}

export const markThreadAsImportant = async (
  thread: string,
  participant: string,
) => {
  return await fetcher(`/conversations/${thread}/important`, {
    method: "POST",
    body: JSON.stringify({ participant }),
  })
}

export const sendMessageToMultiple = async (body: TMessageMultiple) => {
  return await fetcher(`/conversations/multiple`, {
    method: "POST",
    body: JSON.stringify(body),
  })
}

export const sendMessage = async ({
  conversation,
  message,
  attachments,
  mail = true,
}: {
  conversation: string
  message: string
  attachments?: string[]
  mail?: boolean
}): Promise<TMessage> => {
  return await fetcher(`/conversations/${conversation}/messages`, {
    method: "POST",
    body: JSON.stringify({ content: message, attachments, mail }),
  })
}

export const markThreadAsRead = async (thread: string, participant: string) => {
  return await fetcher(`/conversations/${thread}/messages/mark_as_read`, {
    method: "PUT",
    body: JSON.stringify({ participant }),
  })
}

export const unmarkThreadAsImportant = async (
  thread: string,
  participant: string,
) => {
  return await fetcher(`/conversations/${thread}/important`, {
    method: "DELETE",
    body: JSON.stringify({ participant }),
  })
}
