import Image from "next/image"
import React from "react"

const illustrations = [
  "bill-empty",
  "blogs-empty",
  "calender-empty",
  "contract_template",
  "dashboard-empty",
  "data-empty",
  "document-empty",
  "email-empty",
  "folder-empty",
  "hiring",
  "inbox-empty",
  "message-empty",
  "messages",
  "navigation-empty",
  "notification-empty",
  "photos-empty",
  "RFQ",
  "search-empty",
  "tags-empty",
  "task-empty",
  "upload-empty",
  "user-empty",
  "wallet-empty",
  "flextribe-logo",
  "flextribe-x",
] as const

type IllustrationName = (typeof illustrations)[number]

type IllustrationProps = {
  name: IllustrationName
  alt?: string
  width?: number
  height?: number
}

/**
 * Illustration component to render SVG illustrations from the public/illustrations directory.
 *
 * @param {IllustrationProps} props - The props for the Illustration component.
 * @param {IllustrationName} props.name - The name of the illustration to render.
 * @param {string} [props.alt] - The alt text for the illustration. Defaults to the name.
 * @param {number} [props.width=180] - The width of the illustration. Defaults to 180.
 * @param {number} [props.height=180] - The height of the illustration. Defaults to 180.
 * @returns {JSX.Element} The rendered illustration.
 */
export const Illustration: React.FC<IllustrationProps> = ({
  name,
  alt,
  width = 180,
  height = 180,
}) => {
  if (!illustrations.includes(name)) {
    throw new Error(`Illustration "${name}" is not available.`)
  }

  return (
    <Image
      src={`/illustrations/${name}.svg`}
      alt={alt || name}
      width={width}
      height={height}
    />
  )
}
