import { TextInput } from "@/shared/components/fields"
import Link from "next/link"
import { useEffect, useState } from "react"
import {
  GoogleIcon,
  MicrosoftIcon,
} from "../../../components/icons/social-icon"

import { getThemeImageUrl } from "@/utils/helpers"
import {
  faEnvelopeOpen,
  faExclamationCircle,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTheme } from "context/theme-context"
import { useSearchParams } from "next/navigation"
import { useRouter } from "next/router"
import { toast } from "sonner"
import { Loader } from "../../../components/spinners/loader"
import { getAuthLink, magicLink } from "../../../utils/api/authentication"

export function Login() {
  const router = useRouter()

  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [authLinks, setAuthLinks] = useState({ google: "", microsoft: "" })
  const [successMessage, setSuccessMessage] = useState(null)
  const theme = useTheme()
  const isSubmitDisabled = email === ""
  const bgImage = getThemeImageUrl(theme?.authimg)
  const authLogo = getThemeImageUrl(theme?.authlogo)
  const searchParams = useSearchParams()
  const returnUrl = searchParams.get("returnUrl")

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !isSubmitDisabled) {
      submitLogin()
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const errorMsg = searchParams.get("error")
      if (!!errorMsg) {
        setError(errorMsg)
        toast.error(errorMsg)
      }
    }
  }, [router])

  useEffect(() => {
    setIsLoading(false)
    setAuthLinks({
      google: getAuthLink("google", {
        returnUrl,
      }),
      microsoft: getAuthLink("microsoft", {
        returnUrl,
      }),
    })
  }, [returnUrl])

  const submitLogin = async () => {
    setIsLoading(true)
    setError(null)
    try {
      await magicLink({ email, returnUrl })
      setSuccessMessage("Magic link is sent")
    } catch (error) {
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <div
        style={{ backgroundImage: bgImage }}
        className="login fixed z-[100] h-full w-full bg-cover bg-fixed px-4 py-16"
      ></div>
      <div className="absolute top-[50%] z-[101] flex w-full translate-y-[-50%] items-center">
        <div
          className={`${
            theme?.login_color ?? theme?.login_screen?.color ?? "text-primary"
          } max-sm:hidden sm:w-7/12 sm:pl-4 lg:w-8/12 lg:pl-10 2xl:w-9/12 2xl:pl-60 2xl:pr-60`}
        >
          <p className="pr-4 font-light sm:text-4xl lg:text-6xl">
            {theme?.login_headline ?? theme?.login_screen?.title}
          </p>
          <p className="pt-8 text-xl md:pr-20 lg:pr-40">
            {theme?.login_description ?? theme?.login_screen?.statement}
          </p>
        </div>
        <div className="ml-auto items-end justify-center max-sm:ml-4 max-sm:mr-4 max-sm:w-full md:mr-4 md:w-5/12 lg:mr-10 lg:w-4/12 2xl:mr-40 2xl:w-3/12">
          <div className="mb-4 mt-16 w-full rounded-xl bg-white px-10 pb-10 pt-6 shadow">
            <div
              style={{ backgroundImage: authLogo }}
              className="mx-auto h-16 w-60 bg-contain bg-center bg-no-repeat pb-8"
            ></div>
            {successMessage ? (
              <div className="flex flex-col">
                <div className="mt-4 flex justify-center">
                  Check your email inbox for the verification link.
                </div>
                <div className="mt-4 flex justify-center">
                  <FontAwesomeIcon
                    icon={faEnvelopeOpen}
                    className="h-14 w-14 text-blue-600"
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="flex w-full items-center justify-between pt-5">
                  <hr className="w-full bg-gray-400" />
                  <p className="select-none px-2 text-xs font-medium uppercase leading-4 text-gray-400">
                    User
                  </p>
                  <hr className="w-full bg-gray-400" />
                </div>
                <div className="mt-4 w-full">
                  <TextInput
                    value={email}
                    onChange={(v) => {
                      setEmail(v.target.value)
                    }}
                    label="Email"
                    name="email"
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="mt-8">
                  {error !== null ? (
                    <div className="error-msg">
                      <FontAwesomeIcon icon={faExclamationCircle} />
                      <div className="pl-2 pt-[2px]">
                        <span className="font-medium">Woops!</span> {error}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <button
                    role="button"
                    className="w-full rounded-xl border bg-blue-600 py-4 text-lg font-medium leading-none text-white hover:opacity-80"
                    disabled={isSubmitDisabled || isLoading}
                    onClick={submitLogin}
                  >
                    {isLoading ? (
                      <div className="mx-auto w-20">
                        <Loader />
                      </div>
                    ) : (
                      `Sign in`
                    )}
                  </button>
                </div>
                <div className="flex w-full items-center justify-between pt-5">
                  <hr className="w-full bg-gray-400" />
                  <p className="select-none px-2 text-xs font-medium uppercase leading-4 text-gray-400">
                    OR
                  </p>
                  <hr className="w-full bg-gray-400" />
                </div>
                <Link href={authLinks.google}>
                  <button
                    aria-label="Continue with google"
                    role="button"
                    className="mt-10 flex w-full items-center rounded-xl border border-gray-300 px-4 py-3.5 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-1"
                  >
                    <div className="fill-primary h-6 w-6">
                      <GoogleIcon />
                    </div>
                    <p className="ml-4 text-base font-medium text-gray-700">
                      Continue with Google
                    </p>
                  </button>
                </Link>
                <Link href={authLinks.microsoft}>
                  <button
                    aria-label="Continue with github"
                    role="button"
                    className="mt-4 flex w-full items-center rounded-xl border border-gray-300 px-4 py-3.5 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-1"
                  >
                    <div className="fill-primary h-6 w-6">
                      <MicrosoftIcon />
                    </div>
                    <p className="ml-4 text-base font-medium text-gray-700">
                      Continue with Office 365
                    </p>
                  </button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
