import { cn } from "@/utils/helpers"
import { cva, VariantProps } from "class-variance-authority"
import * as React from "react"

const dividerVariants = cva("bg-outline", {
  variants: {
    direction: {
      horizontal: "w-full h-px my-2",
      vertical: "h-full w-px mx-2",
    },
  },
  defaultVariants: {
    direction: "horizontal",
  },
})

export interface DividerProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof dividerVariants> {}

const Divider = React.forwardRef<HTMLDivElement, DividerProps>(
  ({ className, direction, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(dividerVariants({ direction }), className)}
        {...props}
      />
    )
  },
)
Divider.displayName = "Divider"

export { Divider }
