import { useEffect, useState } from "react"
import { useRouter } from "next/router"

const useNavigationHistory = () => {
  const [history, setHistory] = useState([])
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url) => {
      setHistory((prev) => [...prev, url])
    }

    router.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  const goBack = () => {
    setHistory((prev) => prev.slice(0, -1))
    router.back()
  }

  return { history, goBack }
}

export default useNavigationHistory
